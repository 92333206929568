<template>
  <v-card>
    <v-card-text class="mt-4">
      <v-row justify="end">
        <v-col cols="2" class="text-end">
          <v-btn
            small
            elevation="0"
            class="mb-2 bg-success"
            dark
            @click="_editStudentRecord({}, false)"
            >Бүртгэл+
          </v-btn>
        </v-col>
      </v-row>
      <v-progress-linear
        v-if="loading"
        color="red"
        height="6"
        indeterminate
      ></v-progress-linear>
      <v-data-table
        v-if="studentRecords"
        :headers="[
          {
            text: 'No.',
            value: 'index',
          },
          {
            text: 'Огноо',
            value: 'Date',
          },
          {
            text: selectedFormData.description1,
            value: 'description1',
          },
          {
            text: selectedFormData.description2,
            value: 'description2',
          },
          {
            text: 'Үйлдэл',
            value: 'actions',
            width: '10%',
          },
        ]"
        :items="studentRecords"
        :search="search"
        hide-details
        hide-default-footer
        :items-per-page="-1"
      >
        <template slot="item" slot-scope="props">
          <tr>
            <td @click="_print(props.item)">{{ props.item.index }}</td>
            <td>
              <span v-if="props.item.date"> {{ props.item.date }} </span>
            </td>
            <td>
              {{ props.item.description1 }}
            </td>
            <td>
              {{ props.item.description2 }}
            </td>

            <td style="width: 15%">
              <template>
                <v-icon
                  small
                  class="mr-2"
                  @click.stop="_deleteStudentRecord(props.item)"
                >
                  mdi-delete
                </v-icon>
                <v-icon
                  small
                  class="mr-2"
                  @click.stop="_editStudentRecord(props.item, true)"
                >
                  mdi-pencil
                </v-icon>
                <!-- <v-btn
                  v-if="selectedFormData.withAttendances"
                  text
                  class="red--text"
                >
                  Бүртгэx
                </v-btn> -->
              </template>
            </td>
          </tr>
        </template>
        <v-alert slot="no-results" :value="true" color="error" icon="warning">
          Your search for "{{ search }}" found no results.
        </v-alert>
      </v-data-table>
    </v-card-text>
    <v-dialog
      scrollable
      v-model="newStudentRecordDialog"
      max-width="40%"
      v-if="editedItem"
    >
      <v-card class="py-4">
        <v-card-title class="headline black--text">
          {{ selectedFormData.dialogTitle }}</v-card-title
        >
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <h3>
                  Бүртгэлийн огноо:
                  <span class="red--text">{{ editedItem.date }}</span>
                </h3>
              </v-col>
              <v-col class="text-end">
                <v-btn
                  class="text-capitalize"
                  :class="editedItem.date ? 'grey' : 'red'"
                  dark
                  elevation="0"
                  small
                  @click="_showSelectDateDialog"
                >
                  <span v-if="editedItem.date">засаx</span>
                  <span v-else>Огноо+</span>
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12" class="pt-0">
                <!-- <v-select
                  label="Сурагч сонгоx"
                  :items="students"
                  item-text="name2"
                  item-value="id"
                  return-object
                  v-model="editedItem.selectedStudent"
                ></v-select> -->
              </v-col>
              <!-- <v-col cols="12" sm="12" md="12" class="pt-0">
                <v-select
                  label="Амжилтын төрлийг сонгоx"
                  :items="leaderBoardTypes"
                  item-text="name2"
                  item-value="id"
                  return-object
                  v-model="editedItem.leaderBoardType"
                ></v-select>
              </v-col> -->
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  v-model="editedItem.description1"
                  hide-details
                  outlined
                  :label="selectedFormData.description1"
                  color="rgba(0,0,0,.6)"
                  value="Say a few words about who you are or what you're working on."
                  class="font-size-input border text-light-input border-radius-md mt-2"
                ></v-textarea>
                <v-textarea
                  v-model="editedItem.description2"
                  hide-details
                  outlined
                  :label="selectedFormData.description2"
                  color="rgba(0,0,0,.6)"
                  value="Say a few words about who you are or what you're working on."
                  class="font-size-input border text-light-input border-radius-md mt-2"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="mx-4">
          <v-spacer />
          <v-btn color="blue" text @click="_closeEdit"> Цуцлаx </v-btn>
          <v-btn
            class="bg-gradient-primary text-capitalize"
            dark
            elevation="0"
            @click="_save"
          >
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="showDateSelectDialog" max-width="400px">
      <v-card>
        <v-card-text class="text-center pb-0">
          <v-date-picker
            v-model="selectedDate"
            color="green lighten-1"
            header-color="green"
          ></v-date-picker>
        </v-card-text>

        <v-card-text class="text-center pb-6">
          <v-btn
            class="mr-2"
            @click="showDateSelectDialog = !showDateSelectDialog"
            >Цуцлах</v-btn
          >
          <v-btn class="ml-2 bg-gradient-success" dark @click="_selectDate"
            >Сонгох</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import moment from "moment";
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
// const fb = require("@/firebaseConfig.js");
export default {
  components: {},
  data: () => ({
    loading: false,
    selectedDate: null,
    showDateSelectDialog: false,
    editedItem: null,
    newStudentRecordDialog: false,
    search: null,
    studentRecords: null,
    headerNames: [
      {
        text: "No",
        align: "end",
        value: "index",
        sortable: true,
        width: "1%",
        fixed: true,
      },
      {
        text: "Овог, нэр",
        align: "start",
        sortable: true,
        value: "firstName",
      },
      {
        text: "Тайлбар",
        align: "start",
        sortable: true,
        value: "description",
      },
      {
        text: "Огноо",
        align: "start",
        sortable: true,
        value: "createdAt",
      },
      {
        text: "Үйлдэл",
        align: "start",
        sortable: true,
        value: "actions",
      },
    ],
  }),
  props: {
    selectedProgram: {
      required: true,
      type: Object,
    },
    collectionPath: {
      required: true,
      type: String,
    },
    selectedFormData: {
      required: true,
      type: Object,
    },
  },
  watch: {},
  computed: {
    ...mapState(["userData", "currentUser"]),
    ...sync("*"),
  },
  created() {
    if (!this.studentRecords) {
      this.userData.school.ref
        .collection("departments-" + this.userData.school.currentYear)
        .doc("department-" + this._getMyClassInfo("ACADEMIC_LEVEL"))
        .collection("programs")
        .doc(this._getMyClassInfo("STUDENT_GROUP_ID").toString())
        .collection(this.collectionPath)
        .orderBy("createdAt", "desc")
        .onSnapshot((docs) => {
          this.studentRecords = [];
          var counter = 0;
          docs.forEach((doc) => {
            counter++;
            let rec = doc.data();
            rec.id = doc.id;
            rec.ref = doc.ref;
            rec.index = counter;
            this.studentRecords.push(rec);
          });
        });
    }
  },
  methods: {
    _selectDate() {
      this.editedItem.date = this.selectedDate;
      this.showDateSelectDialog = !this.showDateSelectDialog;
    },
    _showSelectDateDialog() {
      this.selectedDate = this.editedItem.date ? this.editedItem.date : null;
      this.showDateSelectDialog = !this.showDateSelectDialog;
    },
    _print(val) {
      console.log(val.ref.path);
    },
    _deleteStudentRecord(item) {
      console.log(item);
      this.editedItem = item;
      this.$swal({
        title: "Мэдээллийг устгаx уу?",
        text: item.selectedStudent
          ? item.selectedStudent.firstName
          : "" + ", " + item.description,
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.editedItem.ref.delete();
        }
      });
    },
    _save() {
      if (this.editedItem.date) {
        if (this.editedItem.ref) {
          this.editedItem.updatedBy = this.userData.ref;
          this.editedItem.ref.update(this.editedItem).then(() => {
            this._closeEdit();
          });
        } else {
          this.editedItem.createdAt = new Date();
          this.editedItem.createdBy = this.userData.ref;
          this.selectedProgram.ref
            .collection(this.collectionPath)
            .doc()
            .set(this.editedItem, { merge: true });
          this._closeEdit();
        }
      } else {
        this.$swal.fire("Бүртгэлийн огноо сонгоx!");
      }
    },
    _closeEdit() {
      this.editedItem = null;
      this.newStudentRecordDialog = !this.newStudentRecordDialog;
    },
    _editStudentRecord(item, editing) {
      var currentDate = new Date();
      this.editing = editing;
      this.editedItem = Object.assign({}, item);
      if (!editing) {
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, "0");
        const day = String(currentDate.getDate()).padStart(2, "0");
        this.editedItem.date = `${year}-${month}-${day}`;
      } else {
        if (!this.editedItem.date) {
          const year = currentDate.getFullYear();
          const month = String(currentDate.getMonth() + 1).padStart(2, "0");
          const day = String(currentDate.getDate()).padStart(2, "0");
          this.editedItem.date = `${year}-${month}-${day}`;
        }
      }
      this.newStudentRecordDialog = true;
    },
    _getDayFormatted2(item) {
      return moment(item.createdAt.toDate()).format("YY/MM/DD");
    },
    _getMyClassInfo(value) {
      if (this.$attrs.zClassGroup) return this.$attrs.zClassGroup[value];
      else
        return this.userData["myClassInfo-" + this.userData.school.currentYear][
          value
        ];
    },
  },
};
</script>
