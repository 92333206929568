<template>
  <v-card class="my-4">
    <v-tabs v-model="xSelectedTab" class="">
      <v-tab
        class="text-capitalize"
        :class="{ 'active-tab': xSelectedTab === myClassesIndex }"
        v-for="(myClass, myClassesIndex) in xTabs"
        :key="'myClass' + myClassesIndex"
      >
        {{ myClass.name }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="xSelectedTab" class="">
      <v-tab-item
        v-for="(myClass, myClassesIndex) in xTabs"
        :key="'myClass' + myClassesIndex"
      >
        <ClassNote
          :selectedProgram="selectedProgram"
          :collectionPath="myClass.collectionPath"
          :selectedFormData="xTabs[xSelectedTab]"
        ></ClassNote>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>
<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import ClassNote from "@/views/Pages/MyClass/ClassNote.vue";
// const fb = require("@/firebaseConfig.js");
export default {
  components: {
    ClassNote,
  },
  data: () => ({
    xSelectedTab: null,
    xTabs: [
      {
        name: "Сургалтыг дэмжих үйл ажиллагаа",
        dialogTitle: "Сургалтыг дэмжих үйл ажиллагаа",
        collectionPath: "_classTrainingReports",
        description1: "Хичээлээс гадуур явагдсан ажлын агуулга",
        description2: "Үр дүнгийн тухай тэмдэглэл",
        withAttendances: true,
      },
      {
        name: "Эрүүл мэндийн үзлэг, судалгаа",
        dialogTitle: "Эрүүл мэндийн үзлэг, судалгаа",
        collectionPath: "_classHealthReports",
        description1: "Хичээлээс гадуур явагдсан ажлын агуулга",
        description2: "Үр дүнгийн тухай тэмдэглэл",
        withAttendances: true,
      },
      {
        name: "Эцэг эхийн хамтын ажиллагаа",
        dialogTitle: "Багш, эцэг эхийн хамтын ажиллагаа",
        collectionPath: "_classParentReports",
        description1: "Үйл ажиллагаа",
        description2: "Тэмдэглэл",
        withAttendances: true,
      },
    ],
    loading: false,
  }),
  props: {
    selectedProgram: {
      required: true,
      type: Object,
    },
  },
  watch: {},
  computed: {
    ...mapState(["userData", "currentUser"]),
    ...sync("*"),
  },

  methods: {},
};
</script>
<style>
.active-tab {
  background-color: #ffc107;
  /* Your desired background color */
  color: #ffffff;
  /* Your desired text color */
}
</style>